import React from "react";
import axios from "axios";
import { useEffect, useRef } from "react";
import { Navigate, Outlet } from 'react-router-dom';
import cookie from "react-cookies";
import Interceptor from "../../modules/interceptor";
import { width } from "@mui/system";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export let appURL = ""
export let appMainURL = ""
if (process.env.REACT_APP_MODE === 'prod') {
    console.log = function () { };
    appURL = "shopyxa.com"
    appMainURL = "appshopyxa.com"
} else if (process.env.REACT_APP_MODE === 'dev') {
    appURL = "forgetaday.com"
    appMainURL = appURL
} else {
    appURL = "shopyxo.com"
    appMainURL = "appshopyxo.com"
}

export const appBaseURL = `https://api.${appURL}/v1`
export const Api = axios.create({
    baseURL: appBaseURL
});



// export const env = "dev"
export const accountUrl = `https://accounts.${appURL}/`

export const getFileType = (file) => {
    if (file) {
        if (file.split("/video/").length > 1) {
            return <video muted playsInline src={file} width="100%" height="100%" />
        } else {
            return <img src={file} />
        }
    }
}
export const getFileThumb = (file) => {
    if (file) {
        if (file.split("/video/").length > 1) {
            return <span className="video_product_thumb"></span>
        } else {
            return <img src={file} style={{width: "100%", height: "100%", objectFit: "cover"}} />
        }
    }else{
        return <span className="image_product_thumb">
            <AddPhotoAlternateIcon color="info" fontSize='large' />
        </span>
    }
}

export const getId = (url) => {
    if (url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const regExp2 = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
        const match = url.match(regExp);
        const match2 = url.match(regExp2);
        if (match !== null && match2 === null) {
            return (match && match[2].length === 11)
                ? match[2]
                : null;
        } else if (match === null && match2 !== null) {
            return "vimeo"
        }
    }
}
let option = {
    path: "/"
};
export const setCookies = (name, data) => {
    cookie.save(name, data, option);
};
export const setLocationCookies = (name, data, options) => {
    cookie.save(name, data, options);
};
export const getCookies = () => {
    let allCookie = cookie.loadAll();
    return {
        session_id: allCookie.session_id || "",
        session_token: allCookie.session_token || "",
        login_token: allCookie.login_token || "",
        user_loc: allCookie.user_loc || false,
        theme_id: allCookie.theme_id || process.env.DEFAULT_THEME_ID,
    }
};
export const removeThemeCookies = () => {
    cookie.remove("theme_id", option);
};
export const removeCookies = () => {
    cookie.remove("session_id", option);
    cookie.remove("session_token", option);
};

export const setToken = (token, id, role) => {
    localStorage['token'] = token;
    localStorage['ID'] = id;
    localStorage['ROLE'] = role;
};
export const setTokenFE = (val, token) => {
    localStorage['user_type'] = val;
    localStorage['session_token'] = token;
};

export const removeToken = () => {
    localStorage.clear()
    // window.location.reload(false)
    setTimeout(() => {
        window.open(accountUrl, '_self')
    }, 1000);
};
export const removeTokenFE = () => {
    localStorage["user_type"] = "GUEST"
    localStorage.removeItem("guest_id")
    // localStorage.removeItem("CART")
    // window.location.reload(false)
};

const searchParams = new URLSearchParams(window.location.search)

export const isLogIn = () => {
    if (localStorage.getItem('token')) {
        return { isToken: true, token: localStorage.getItem('token') };
    } else {
        return { isToken: false, token: '' };
    }
};
export const isTokenFE = () => {
    if (localStorage.getItem('session_token')) {
        return { isToken: true, token: localStorage.getItem('session_token') };
    } else {
        return { isToken: false, token: '' };
    }
};
export const isLogInFE = () => {
    if (localStorage.getItem('user_type')) {
        return { user_type: localStorage.getItem('user_type') };
    } else {
        return { user_type: "GUEST" };
    }
};
console.log("isLogInFE: ", isLogInFE())

export const PrivateRoute = () => {
    return <Outlet />
}
export const PrivateRouteFE = () => {
    console.log("isLogInFE().PrivateRouteFE: ", isLogInFE().user_type)
    return isLogInFE().user_type !== "GUEST" ? <Outlet /> : <Navigate to="/login" />;
}
export const PublicRouteFE = () => {
    console.log("isLogInFE().PublicRouteFE: ", isLogInFE().user_type)
    if (searchParams.get("payment_url") && searchParams.get("user_type")) {
        localStorage["user_type"] = searchParams.get("user_type")
        return <Outlet />
    } else {
        return isLogInFE().user_type !== "GUEST" ? <Navigate to="/myaccount" /> : <Outlet />;
    }
}


export const toFixed = (val) => {
    return (val).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
}

export const useUnload = fn => {
    const cb = useRef(fn);
    useEffect(() => {
        cb.current = fn;
    }, [fn]);

    useEffect(() => {
        const onUnload = (...args) => cb.current?.(...args);

        window.addEventListener("beforeunload", onUnload);

        return () => window.removeEventListener("beforeunload", onUnload);
    }, []);
};


export const alphabets = [
    'a', 'b', 'c', 'd', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
    'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'F', 'G', 'H', 'I', 'J',
    'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '@', '~', '!', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', '-', '=', '[', ']', '{', '}', '"', ';', ':', '<', ',', '>', '/', '?', '|', '`', "'"
];



const headers = () => {
    if (isLogIn().token) {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "token": isLogIn().token,
        }
    } else {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
        }
    }
}

const headersFE = () => {
    if (isTokenFE().token) {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Authorization": isTokenFE().token,
        }
    } else {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
        }
    }
}

function returnAxiosInstance(customHeaders = headers()) {
    return Interceptor({
        baseURL: appBaseURL,
        headers: customHeaders,
    });
}
function returnAxiosInstanceFormdata(customHeaders = headers()) {
    return Interceptor({
        baseURL: appBaseURL,
        headers: customHeaders,
        type: "formdata"
    });
}
function returnAxiosInstanceFormdataFE(customHeaders = headersFE()) {
    return Interceptor({
        baseURL: appBaseURL,
        headers: customHeaders,
        type: "formdata"
    });
}

//Handle API response
function handleResponse(response) {
    if (response.status === 200 || response.status === 201) {
        return response
    }
    if (response.status === 500 || response.status === 404) {
        return { response: { data: { error: response.data.error } }, error: true }
    }
    if (response.data.errorCode == "PERMISSION_DINED") {
        return <Navigate to="/admin" replace />
    }
    else {
        return { response: { data: { error: response.data.error.message } }, error: true }
    }
}

let errorData = (err) => {
    return { error: err?.response && err?.response?.data ? (typeof err?.response?.data?.error == "string" ? err?.response?.data?.error : err?.response?.data?.error?.message) : "Something went wrong" }
}
let tokenError = (err) => {
    if (err?.response && err?.response?.data) {
        if (err?.response?.data?.errorCode && err?.response?.data?.errorCode == "INVALID_TOKEN" || err?.response?.data?.errorCode == "TOKEN_EXPIRE" || err?.response?.data?.errorCode == "ACCESS_TOKEN_NOT_FOUND") {
            setTimeout(() => {
                window.open(accountUrl, '_self')
            }, 2000);
        }
    } else {
        if (err.response && err.response.data) {
            return err.response.data = ""
        }
    }
}
let tokenErrorFE = (err) => {
    if (err?.response?.data?.errorCode == "INVALID_TOKEN" || err?.response?.data?.errorCode == "TOKEN_EXPIRE" || err?.response?.data?.errorCode == "ACCESS_TOKEN_NOT_FOUND") {
        setTimeout(() => {
            removeTokenFE()
        }, 2000);
    }
}

//API methods Admin
export const GetApiPublic = async (url, customHeaders = { headers: {} }) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.get(url).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const GetApiWithoutError = async (url, customHeaders = headers()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.get(url).then(handleResponse).catch((err) => {
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const GetApi = async (url, customHeaders = headers()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.get(url).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PostApi = async (url, body, customHeaders = headers()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.post(url, body).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PostApiFormdata = async (url, body, customHeaders = headers()) => {
    const axios = returnAxiosInstanceFormdata(customHeaders);
    return axios.post(url, body).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PutApi = async (url, body, customHeaders = headers()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.put(url, body).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PutApiFormdata = async (url, body, customHeaders = headers()) => {
    const axios = returnAxiosInstanceFormdata(customHeaders);
    return axios.put(url, body).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const DeleteApi = async (url, customHeaders = headers()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.delete(url).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}


//API methods frontend
export const GetApiFE = async (url, customHeaders = headersFE()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.get(url).then(handleResponse).catch((err) => {
        tokenErrorFE(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PostApiFE = async (url, body, customHeaders = headersFE()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.post(url, body).then(handleResponse).catch((err) => {
        tokenErrorFE(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PutApiFE = async (url, body, customHeaders = headersFE()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.put(url, body).then(handleResponse).catch((err) => {
        tokenErrorFE(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PutApiFormdataFE = async (url, body, customHeaders = headersFE()) => {
    const axios = returnAxiosInstanceFormdataFE(customHeaders);
    return axios.put(url, body).then(handleResponse).catch((err) => {
        tokenErrorFE(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const DeleteApiFE = async (url, customHeaders = headersFE()) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.delete(url).then(handleResponse).catch((err) => {
        tokenErrorFE(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}