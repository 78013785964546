
import React, { Suspense, useEffect, useState, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Box, LinearProgress, Toolbar, useMediaQuery } from '@mui/material';

import ScrollToTop from './ScrollToTop';
import { drawerWidth } from '../Components/ThemeEditor/Schema/Exports';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import { PrivateRoute, PrivateRouteFE, PublicRouteFE } from './config/Apis';
import _ from 'underscore';
import * as Api from "./config/Apis"
import config from './config';
import { dataValue } from "../Components/ThemeEditor/Layout/CommonReducer";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import { useCallback } from 'react';
import cookie from "react-cookies";
import Interceptor from '../modules/interceptor';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "./App.css";
import '../Components/common/Fileupload/Fileupload.css';
import "../Components/Pages/Product/Product.css";
import "../Components/ThemeEditor/Layout/Layout.css";
import '../Components/Pages/Home/Home.css';
import '../Components/Pages/Orders/Orders.css'
import '../Components/Pages/Orders/OrderDetail/OrderDetail.css'
import '../Components/Pages/Reports/Report.css'
import '../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Cart/Cart.css'
import '../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Auth/Auth.css'
import '../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Auth/Register.css'
import '../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/BlogList/BlogList.css'
import '../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/MyAccount/MyAccount.css'

import noStore from "../Assets/images/no-result/order2.svg";
import axios from 'axios';
import moment from 'moment';
import NoPageFE from '../Components/ThemeEditor/Layout/Container/Templates/Template1/Components/Common/NoPage';
import InventoryHistory from '../Components/Pages/Product/Variants/InventoryHistory';
import Exchange from '../Components/Pages/Orders/Exchange/Exchange';
import ExchaneOrderDetail from '../Components/Pages/Orders/Exchange/ExchaneOrderDetail';
import VariablesDoc from '../Components/Pages/Settings/Notifications/CustomizeEmail/VariablesDoc';
import PreviewEmail from '../Components/Pages/Settings/Notifications/CustomizeEmail/PreviewEmail';
import AbandonedCheckout from '../Components/Pages/Orders/AbandonedCheckout';
import AbandonedDetail from '../Components/Pages/Orders/AbandonedCheckout/AbandonedDetail';
import Marketing from '../Components/Pages/Settings/Marketing/Marketing';
import Partners from '../Components/Pages/Apps/Partners/Partners';
import GiftCards from '../Components/Pages/Product/GiftCard/GiftCards';
import AddGiftProduct from '../Components/Pages/Product/GiftCard/AddGiftProduct';
import AddGiftCard from '../Components/Pages/Product/GiftCard/AddGiftCard';
import EditGiftCard from '../Components/Pages/Product/GiftCard/EditGiftCard';


const Layout = lazy(() => import('../Components/ThemeEditor/Layout/Layout'))
const Header = lazy(() => import('../Components/Header/Header'))
const FooterMain = lazy(() => import('../Components/Footer/Footer'))
const Sidebar = lazy(() => import('../Components/Sidebar/Sidebar'))

const Home = lazy(() => import('../Components/Pages/Home/Home'))
const Product = lazy(() => import('../Components/Pages/Product/Product'))
const AddProduct = lazy(() => import('../Components/Pages/Product/AddProduct/AddProduct'))
const EditProduct = lazy(() => import('../Components/Pages/Product/EditProduct/EditProduct'))
const AddVariant = lazy(() => import('../Components/Pages/Product/EditProduct/AddVariant/AddVariant'))

// const Layout = lazy(() => import('../Components/ThemeEditor/Layout/Layout'))

const Inventory = lazy(() => import('../Components/Pages/Product/Inventory/Inventory'))
const Category = lazy(() => import('../Components/Pages/Product/Category/Category'))
const AddCategory = lazy(() => import('../Components/Pages/Product/Category/AddCategory/AddCategory'))
const Discount = lazy(() => import('../Components/Pages/Discount/Discount'))
const AddDiscount = lazy(() => import('../Components/Pages/Discount/AddDiscount/AddDiscount'))

const User = lazy(() => import('../Components/Pages/Users/Users'))
const AddUser = lazy(() => import('../Components/Pages/Users/AddUser/AddUser'))
const EditUser = lazy(() => import('../Components/Pages/Users/EditUser/EditUser'))

const StoreDetails = lazy(() => import('../Components/Pages/Settings/StoreDetails/StoreDetails'))
const Plan = lazy(() => import('../Components/Pages/Settings/Plan/Plan'))
const UserAndPermission = lazy(() => import('../Components/Pages/Settings/UserAndPermission/UserAndPermission'))
const Payments = lazy(() => import('../Components/Pages/Settings/Payments/Payments'))
const Notifications = lazy(() => import('../Components/Pages/Settings/Notifications/Notifications'))
const CustomizeEmail = lazy(() => import('../Components/Pages/Settings/Notifications/CustomizeEmail/CustomizeEmail'))
const ShipingMethods = lazy(() => import('../Components/Pages/Settings/ShipingMethods/ShipingMethods'))
const Taxes = lazy(() => import('../Components/Pages/Settings/Taxes/Taxes'))
const EditTaxes = lazy(() => import('../Components/Pages/Settings/Taxes/EditTaxes'))

const Orders = lazy(() => import('../Components/Pages/Orders/Orders'))
const CreateOrder = lazy(() => import('../Components/Pages/Orders/CreateOrder/CreateOrder'))
const OrderDetail = lazy(() => import('../Components/Pages/Orders/OrderDetail/OrderDetail'))
const Refund = lazy(() => import('../Components/Pages/Orders/OrderDetail/Refund/Refund'))
const Restock = lazy(() => import('../Components/Pages/Orders/OrderDetail/Restock/Restock'))
const Return = lazy(() => import('../Components/Pages/Orders/OrderDetail/Return/Return'))

// my store
const Templates = lazy(() => import('../Components/Pages/Store/Templates/Templates'))
const TemplatesView = lazy(() => import('../Components/Pages/Store/Templates/TemplatesView/TemplatesView'))
const Blogs = lazy(() => import('../Components/Pages/Store/Blogs/Blogs'))
const BlogCategory = lazy(() => import('../Components/Pages/Store/Blogs/BlogCategory/BlogCategory'))
const AddBlogCategory = lazy(() => import('../Components/Pages/Store/Blogs/BlogCategory/AddBlogCategory/AddBlogCategory'))
const AddBlog = lazy(() => import('../Components/Pages/Store/Blogs/AddBlog/AddBlog'))

const Pages = lazy(() => import('../Components/Pages/Store/Pages/Pages'))
const AddPages = lazy(() => import('../Components/Pages/Store/Pages/AddPages/AddPages'))

const Preferences = lazy(() => import('../Components/Pages/Store/Preferences/Preferences'))
const DomainVerification = lazy(() => import('../Components/Pages/Store/DomainVerification/DomainVerification'))

const Menu = lazy(() => import('../Components/Pages/Store/Menu/Menu'))
const AddMenuNav = lazy(() => import('../Components/Pages/Store/Menu/AddMenu/AddMenu'))
const EditMenu = lazy(() => import('../Components/Pages/Store/Menu/EditMenu/EditMenu'))

const URlRedirects = lazy(() => import('../Components/Pages/Store/Menu/URlRedirects/URlRedirects'))
const AddURlRedirects = lazy(() => import('../Components/Pages/Store/Menu/URlRedirects/AddURlRedirects/AddURlRedirects'))

const Template1 = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1'))
const NoPage = lazy(() => import('../Components/common/404/NoPage'))

const LandingPage = lazy(() => import('../Components/Pages/LandingPage/LandingPage'))
const Login = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Auth/Login'))
const Collection = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Collection/Collection'))
const Cart = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Cart/Cart'))
const BlogList = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/BlogList/BlogList'))
const BlogsDetail = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/BlogList/BlogsDetail'))
const ProductTheme = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Product/Product'))
const Footer = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Components/Footer/Footer'))
const Maintenance = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Maintenance/Maintenance'))

const Register = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Auth/Register'))
const Verify = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Auth/Verify'))

const BlockRenderFn = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Exports'))
const DraftOrder = lazy(() => import('../Components/Pages/Orders/DraftOrder'))
const MyAccount = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/MyAccount/MyAccount'))
const EditProfile = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/MyAccount/EditProfile/EditProfile'))

const Address = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/MyAccount/Address/Address'))
const EditAddress = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/MyAccount/Address/EditAddress'))

const AccoutOrders = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/MyAccount/AccoutOrders/AccoutOrders'))
const ViewAccoutOrders = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/MyAccount/AccoutOrders/ViewAccoutOrders'))
const ViewOrderItem = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/MyAccount/AccoutOrders/ViewOrderItem'))
const Billing = lazy(() => import('../Components/Pages/Settings/Billing/Billing'))
const Thankyou = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Thankyou/Thankyou'))
const CollectionList = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Collection/CollectionList'))
const PagesFE = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Pages'))
const ForgotPassword = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Auth/ForgotPassword'))
const Profile = lazy(() => import('../Components/Pages/Profile/Profile'))
const ResetPassword = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Auth/ResetPassword'))
const ProductListPage = lazy(() => import('../Components/ThemeEditor/Layout/Container/Templates/Template1/Pages/Product/ProductListPage'))
const CheckoutSetting = lazy(() => import('../Components/Pages/CheckoutSetting/CheckoutSetting'))
const InactiveStore = lazy(() => import('../Components/Pages/InactiveStore'))
const Reports = lazy(() => import('../Components/Pages/Reports/Reports'))
const ReportsProducts = lazy(() => import('../Components/Pages/Reports/ReportsProducts'))
const ReportsProductVariant = lazy(() => import('../Components/Pages/Reports/ReportsProductVariant'))
const ReportsLocation = lazy(() => import('../Components/Pages/Reports/ReportsLocation'))
const ReportsDiscount = lazy(() => import('../Components/Pages/Reports/ReportsDiscount'))
const ReportsCustomers = lazy(() => import('../Components/Pages/Reports/ReportsCustomers'))



const renderLoader = () => <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />;



Interceptor()

function App() {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      background: {
        default: prefersDarkMode ? '#2a2a2a' : "#f9f9f9"
      },
      primary: {
        // main: "#030303",
        // dark: '#161A1D',
        main: "#f1592a",
        dark: '#C0360C',
        contrastText: '#fff'
      },
      secondary: {
        main: prefersDarkMode ? '#fff' : "#000",
        contrastText: prefersDarkMode ? '#fff' : '#000',
      },
      info: {
        main: '#999999',
        contrastText: '#fff'
      },
      success: {
        main: "#52b788",
        dark: "#40916c",
        light: "#74c69d",
        contrastText: '#fff'
      },
      error: {
        main: "#E02500",
        dark: "#891700",
        light: "#00ce56",
        contrastText: '#fff'
      },
      white: {
        main: "#fff"
      }
    },
    typography: {
      fontFamily: "Aktiv Grotesk",
      htmlFontSize: 18,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      allVariants: {
        textTransform: "none",
      },
      h1: { fontWeight: 700, },
      h2: { fontWeight: 700, },
      h3: { fontWeight: 700, },
      h4: { fontWeight: 700, },
      h5: { fontWeight: 700, },
      h6: { fontWeight: 700, },
    }
  });


  const location = window.location.pathname
  const param = useParams()
  const searchParams = new URLSearchParams(window.location.search)
  const [mobileOpen, setMobileOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const dataVal = useSelector((state) => state.dataValue)
  const cartData = useSelector((state) => state.dataValue.cart)
  const [showLoader, setshowLoader] = useState(false)
  const [pageLoad, setPageLoad] = useState(false)
  const [themeId, setThemeId] = useState("")
  const [schemaData, setSchemaData] = useState({})
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  console.log("searchParams.get(theme_id): ", searchParams.get("theme_id"), themeId)
  const cookies = Api.getCookies()
  const { enqueueSnackbar } = useSnackbar();
  const snack = (msg, variant) => {
    enqueueSnackbar(msg, { variant, autoHideDuration: 2000 });
  }
  const [storeData, setStoreData] = useState({})

  const dispatch = useDispatch()
  const [trialMode, settrialMode] = useState(false)
  const [category, setCategory] = useState([])
  const [hasNoMoreData, sethasNoMoreData] = useState(false)
  const [page, setPage] = useState(1)
  const [loadingBtn, setloadingBtn] = useState(false)
  const [quantity, setQuantity] = useState({})
  const [maintenance, setMaintenance] = useState({})
  const [preferences, setPreferences] = useState({})
  const [filterList, setFilterList] = useState([])
  const [blogList, setBlogList] = useState([])
  const [loadingSaveButton, setLoadingSaveButton] = useState(false)
  const [pressed, setPressed] = React.useState(false)
  const [storeDataFE, setstoreDataFE] = React.useState({})
  const [expireStore, setExpireStore] = React.useState(false)
  console.log("process.env.REACT_APP_DEFAULT_THEME_ID: ", process.env.REACT_APP_DEFAULT_THEME_ID)
  //Get store detail
  const getStoreDetail = (type) => {
    Api.GetApi(config.STORE).then(async res1 => {
      console.log("res STORE: ", res1)
      if (res1.error === true) {
        snack(res1.response.data.error, 'error');
      } else {
        if (res1.data.data) {
          if (window.location.host !== res1.data.data?.domain && type == "live") {
            setTimeout(() => {
              window.open(Api.accountUrl, "_self")
            }, 1000);
          } else {
            Api.GetApi(config.STAFF_PERMISSION_LIST).then(async res => {
              console.log("res permission: ", res)
              if (res.error === true) {
                snack(res.response.data.error, 'error');
              } else {
                if (res.data.data) {
                  dispatch(dataValue({ 'access': res.data.data, activeThemeId: res1.data.data?.activeThemeId }))
                  setSchemaData({ ...schemaData, 'access': res.data.data })

                  res.data.data.map((opt) => {
                    if (opt.name == "SETTING") {
                    } else if (opt.name == "PRODUCTS") {
                      getCategory()
                      setTimeout(() => {
                        if (window.location.pathname.includes("/customizer")) {
                          let activeId = _.last(window.location.pathname.split("/"))
                          getTemplates("admin", (searchParams.get("theme_id") && searchParams.get("theme_id") !== "active") || Api.getCookies()?.theme_id || activeId || res1.data.data?.activeThemeId || process.env.REACT_APP_DEFAULT_THEME_ID, "", "customizer")
                        } else {
                          getTemplates("admin", (searchParams.get("theme_id") && searchParams.get("theme_id") !== "active") || Api.getCookies()?.theme_id || res1.data.data?.activeThemeId || process.env.REACT_APP_DEFAULT_THEME_ID, "", "")
                        }
                        let bodyparam = {
                          // store_id: res.data.data?.storeId,
                          status: "active"
                        }
                        storeProducts(Api.PostApi, config.PRODUCT_PRODUCT_LIST, "", bodyparam)
                      }, 500);
                    }
                  })
                  // getStoreDetail(type)

                  //Get user detail
                  Api.GetApi(config.USER_PROFILE).then(resp => {
                    console.log("res STORE: ", resp)
                    if (resp.error === true) {
                      snack(resp.response.data.error, 'error');
                    } else {
                      if (resp.data.data) {
                        dispatch(dataValue({ 'user': resp.data.data }))
                      }
                    }
                  })

                  getCartCookies(res1.data.data?.storeId, (searchParams.get("theme_id") && searchParams.get("theme_id") !== "active") || Api.getCookies()?.theme_id || res1.data.data?.activeThemeId)
                  setThemeId((searchParams.get("theme_id") && searchParams.get("theme_id") !== "active") || Api.getCookies()?.theme_id || res1.data.data?.activeThemeId)
                  setStoreData(res1.data.data)
                  setSchemaData({ ...schemaData, 'store': res1.data.data })
                  dispatch(dataValue({ 'store': res1.data.data }))
                  // let isExpire = false
                  // if (moment(new Date(res1.data.data?.expired_at)).isBefore(new Date())) {
                  dispatch(dataValue({ 'verify': res1.data.data?.user?.is_verified || 0 }))
                  if (res1.data.data?.is_trail) {
                    // isExpire = true
                    settrialMode(true)
                    dispatch(dataValue({ 'trial': true }))
                    setSchemaData({ ...schemaData, 'trial': true })
                  } else {
                    // isExpire = false
                    settrialMode(false)
                    dispatch(dataValue({ 'trial': false }))
                    setSchemaData({ ...schemaData, 'trial': false })
                    if (!res1.data.data?.is_active) {
                      window.open("/admin/inactive-store", "_self")
                    }
                  }
                  getPlan()
                }
              }
            })
          }
        }
      }
    })
  }
  const getPlan = () => {
    Api.GetApi(config.ACTIVE_STORE_PLAN).then(resp => {
      console.log("res plan subscribe: ", resp)
      if (resp.error === true) {
        snack(resp.response.data.error, 'error');
      } else {
        dispatch(dataValue({ 'plan': resp.data.data }))
        ////// if (resp.data.data && resp.data.data?.start_at && resp.data.data?.status == "active") {
        // if (resp.data.data) {
        //   if (resp.data.data?.status == "active") {
        //     settrialMode(false)
        //     dispatch(dataValue({ 'trial': false }))
        //     setSchemaData({ ...schemaData, 'trial': false })
        //     // if (resp.data.data?.start_at && resp.data.data?.current_end && moment(new Date(resp.data.data?.current_end * 1000)).isBefore(new Date())) {
        //   } else if (resp.data.data?.status !== "active" && expire) {
        //     window.open("/admin/inactive-store", "_self")
        //   } else {
        //     settrialMode(true)
        //     dispatch(dataValue({ 'trial': true }))
        //     setSchemaData({ ...schemaData, 'trial': true })
        //   }
        // } else {
        //   settrialMode(true)
        //   dispatch(dataValue({ 'trial': true }))
        //   setSchemaData({ ...schemaData, 'trial': true })
        // }
      }
    })
  }
  //Get staff permissions
  // const getStaffPermissions = (id) => {

  // }

  const getActivePlan = useCallback(() => {
    Api.GetApi(config.ACTIVE_STORE_PLAN).then(resp => {
      console.log("res plan subscribe: ", resp)
      if (resp.error === true) {
        snack(resp.response.data.error, 'error');
      } else {
        if (resp.data.data) {
          settrialMode(false)
        } else {
          settrialMode(true)
        }
      }
    })
  }, [])

  // useEffect(() => {
  //     getActivePlan()
  // }, [])

  //Search store detail by domain
  const searchStoreDetail = (body, id) => {
    console.log("id------------------", id);
    Api.PostApiFE(config.STORE_SEARCH, body).then(async res => {
      console.log("res STORE search: ", res)
      if (res.error === true) {
        if (res.res.data.errorCode == "STORE_NOT_FOUND") {
          setPageLoad(true)
        }
        snack(res.response.data.error, 'error');
      } else {
        if (res.data.data) {
          setstoreDataFE(res.data.data)
          if (res.data.data.status == "expired") {
            setPageLoad(true)
            setExpireStore(true)
          } else {
            setExpireStore(false)
            console.log("res.data.data?.activeThemeId", id, "----", res.data.data?.activeThemeId);
            // document.body.setAttribute("store-id", res.data.data.storeId)
            // document.body.setAttribute("store-name", res.data.data.store_name)
            dispatch(dataValue({ 'store_id': parseInt(res.data.data?.storeId), store_name: res.data.data?.store_name, 'liveDomain': res.data.data?.liveDomain || null, "store_email": res.data.data?.store_email, 'return_exchange_settings': res.data.data?.return_exchange_settings || {} }))
            setSchemaData({ ...schemaData, 'store_id': parseInt(res.data.data?.storeId), store_name: res.data.data?.store_name })
            getCartCookies(parseInt(res.data.data?.storeId), id || res.data.data?.activeThemeId, res.data.data?.store_email)
            setThemeId(id || res.data.data?.activeThemeId)
            if (res.data.data?.liveDomain && res.data.data?.domain == window.location.host) {
              setTimeout(() => {
                window.open(res.data.data?.liveDomain.startsWith("http") ? `${res.data.data?.liveDomain}${window.location.pathname}` : `https://${res.data.data?.liveDomain}${window.location.pathname}`, "_self")
              }, 500);
            }
          }

          // checkMaintenenceMode(parseInt(res.data.data?.storeId))
          // getTemplates(Api.GetApiFE, `${config.TEMPLATE}/1`)
        }
      }
    })
  }
  console.log("themeid", themeId)
  console.log("expireStore", expireStore)


  //Search store products
  const storeProducts = (api, url, params, bodyParam) => {
    api(`${url}${params}`, bodyParam).then(res => {
      console.log("res STORE products: ", res)
      setshowLoader(false)
      if (res.error === true) {
        snack(res.response.data.error, 'error');
      } else {
        if (res.data.data) {
          let productData = []
          if (dataVal && dataVal?.store_products && dataVal?.store_products.length > 0) {
            productData = [...dataVal?.store_products, ...res.data.data]
            // productData.concat(res.data.data.filter((x) => x.status == "Active"))
          } else {
            productData = res.data.data
          }
          dispatch(dataValue({ 'store_products': productData }))
          setSchemaData({ ...schemaData, 'store_products': productData })
          if (res.data.data.length < 20) {
            sethasNoMoreData(true)
          } else {
            sethasNoMoreData(false)
          }
        }
      }
    })
  }

  const fetchMoreData = () => {
    console.log("yessssssssssssssssssssssssssss")
    setshowLoader(true)
    if (dataVal && dataVal?.store_id) {
      setTimeout(() => {
        setPage(page + 1)
        let bodyparam = {
          status: "active",
        }
        storeProducts(Api.PostApiFE, config.CUSTOMER_PRODUCT_LIST, `?page=${page + 1}`, bodyparam)
      }, 1000);
    }
  }

  const getCategory = () => {
    Api.GetApi(`${config.CATEGORY_LIST}?page=1`).then(resp => {
      if (resp.error === true) {
        snack(resp.response.data.error, 'error');
      } else {
        let arr = []
        resp.data.data.map((x) => {
          arr.push({ id: x.category_id, value: x.handle, name: x.title })
        })
        setCategory(arr)
        dispatch(dataValue({ "categories": arr }))
        setSchemaData({ ...schemaData, "categories": arr })
      }
    })
  }
  //get cart
  const getCartFn = (store_id, theme_ID) => {
    Api.GetApiFE(config.CART).then(res => {
      console.log("res cart: ", res)
      if (res.error === true) {
        if (res.response.data.error !== "Invalid token") {
          snack(res.response.data.error, 'error');
        } else {
          // getCartTokenFn(dataVal && dataVal?.store_id, "", "token")
          getBlankCartToken(theme_ID, dataVal && dataVal?.store_id, "token")
        }
      } else {
        if (res.data.data) {
          dispatch(dataValue({ 'cart': res.data.data?.cart }))
          setSchemaData({ ...schemaData, 'cart': res.data.data?.cart })
        }
      }
    })
  }
  const getUpdatedCartFn = () => {
    Api.GetApiFE(config.CART).then(res => {
      console.log("res cart: ", res)
      if (res.error === true) {
        if (res.response.data.error !== "Invalid token") {
          snack(res.response.data.error, 'error');
        } else {
          // getCartTokenFn(dataVal && dataVal?.store_id, "", "token")
          getBlankCartToken(themeId, dataVal && dataVal?.store_id, "token")
        }
      } else {
        if (res.data.data) {
          dispatch(dataValue({ 'cart': res.data.data?.cart }))
          setSchemaData({ ...schemaData, 'cart': res.data.data?.cart })
        }
      }
    })
  }
  useEffect(() => {
    if (!localStorage.getItem("user_type")) {
      localStorage["user_type"] = "GUEST"
    }
  }, [])

  //get cart token
  const getCartTokenFn = (theme_ID, store_id, param, type, email) => {
    // let url = ""
    // //   url = `${config.CART_TOKEN}/${store_id}?authtoken=${Api.isTokenFE().token}`
    // // } else {
    // //   url = `${config.CART_TOKEN}/${store_id}${param}`
    // // }
    // if (Api.isTokenFE().token) {
    //   getTemplates(Api.GetApiFE, `${config.TEMPLATE}/1`)
    //   checkMaintenenceMode(store_id)
    //   // dispatch(dataValue({ 'store_id': "4441393721973", store_name: "Weo test store" }))
    //   // setSchemaData({ ...schemaData, 'store_id': "4441393721973", store_name: "Weo test store" })
    //   // checkMaintenenceMode(Api.getCookies()?.session_id || "", "4441393721973")

    // } else {
    if (store_id) {
      Api.GetApiPublic(`${config.CART_TOKEN}/${store_id}${param}`).then(res => {
        console.log("res cart token: ", res)
        console.log("res cart token 2: ", localStorage.getItem("user_type"))
        if (res.error === true) {
          if (res.response.data.error !== "Invalid token") {
            snack(res.response.data.error, 'error');
          }
        } else {
          if (res.data.data) {
            if (localStorage.getItem("user_type") && localStorage.getItem("user_type") !== "USER") {
              localStorage["session_token"] = res.data.data?.token
            }

            getProfile()
            getCartFn(store_id, theme_ID)
            if (type !== 'token') {
              getTemplates("", theme_ID || process.env.REACT_APP_DEFAULT_THEME_ID, email, "")
              checkMaintenenceMode(store_id)
              getUpdatedCartFn()
            }
          }
        }
      })
    }
  }

  const getProfile = () => {
    Api.GetApiFE(config.CUSTOMER_PROFILE).then(res => {
      console.log("res CUSTOMER_PROFILE: ", res)
      if (res.error === true) {
        snack(res.response.data.error, 'error');
      } else {
        if (res.data.data && res.data.data?.user_token) {
          localStorage["guest_id"] = res.data.data?.user_token
        }
      }
    })
  }


  const addToCartTokenFn = (param, data, val, active) => {
    Api.GetApiFE(`${config.CART_TOKEN}/${param}`).then(res => {
      console.log("res cart token: ", res)
      if (res.error === true) {
        if (res.response.data.error !== "Invalid token") {
          snack(res.response.data.error, 'error');
        }
      } else {
        if (res.data.data) {
          localStorage["session_token"] = res.data.data?.token
          // if (dataVal && dataVal?.store_id) {
          //   getCartFn(res.data.data?.token, dataVal && dataVal?.store_id)
          // }
          cartFn(data, val, active)
        }
      }
    })
  }
  console.log("getCookies: ", Api.getCookies())

  const cartFn = (data, val, active) => {
    if (localStorage.getItem("locData") && !_.isEmpty(JSON.parse(localStorage.getItem("locData")))) {
      let loc_data = JSON.parse(localStorage.getItem("locData"))
      if (loc_data?.latitude && loc_data?.latitude !== "" && loc_data?.longitude && loc_data?.longitude !== "") {
        data["latitude"] = loc_data?.latitude || ""
        data["longitude"] = loc_data?.longitude || ""
      }
      if (loc_data?.city && loc_data?.city !== "" && loc_data?.state && loc_data?.state !== "") {
        data["state"] = loc_data?.state || "NA"
        data["city"] = loc_data?.city || "NA"
      }
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        data["latitude"] = position.coords.latitude || ""
        data["longitude"] = position.coords.longitude || ""
      })
    }
    console.log("val add to cart: ", val)
    console.log("data add to cart active: ", active)
    Api.PostApiFE(config.CART, data).then(async res => {
      console.log("res add to cart: ", res)
      if (res.error === true) {
        if (res.res.data.errorCode == "INVALID_TOKEN") {
          Api.removeTokenFE()
          addToCartTokenFn("", data, val, active)
          // if (dataVal && dataVal?.store_id) {
          //   addToCartTokenFn(dataVal && dataVal?.store_id, "", data)
          // }
        } else {
          snack(res.response.data.error, 'error');
        }
      } else {
        if (res.data.data) {
          if (window.innerWidth > 767) {
            snack(res.data.data, 'success');
          }
          let arr = []
          console.log("data add to cart: ", data)
          let categ = {}
          val.category.length > 0 && val.category.map((cat, i) => {
            return categ[`item_category${i > 0 ? i + 1 : ""}`] = cat?.title
          })
          arr.push({
            ...categ,
            item_id: active?.variant_id,
            item_name: val?.title || "",
            affiliation: dataVal && dataVal?.store_name || "",
            item_brand: val?.vender || "",
            product_type: val?.product_type || "",
            item_variant: active?.title !== "Default Title" ? active?.title : val?.title || "",
            price: active?.price,
            quantity: data?.quantity || 1
          })

          let data2 = {
            currency: config?.CURRENCY_CODE,
            value: active?.price * data?.quantity || "0",
            product_id: val?.product_id || "",
            variant_id: active?.variant_id,
            items: arr
          }
          console.log("add_to_cart", data2)
          ReactGA.event("add_to_cart", data2);
          ReactPixel.track("AddToCart", data2);

          getUpdatedCartFn()
          dispatch(dataValue({ 'showCart': true }))
          setSchemaData({ ...schemaData, 'showCart': true })
          setTimeout(() => {
            dispatch(dataValue({ 'showCart': false }))
            setSchemaData({ ...schemaData, 'showCart': false })
          }, 2000);

        }
      }
    })
  }

  //Add to cart
  const addToCartFn = (data, val, active) => {
    if (Api.isTokenFE().token) {
      cartFn(data, val, active)
    } else {
      addToCartTokenFn("", data, val, active)
      // if (dataVal && dataVal?.store_id) {
      //   addToCartTokenFn(dataVal && dataVal?.store_id, "", data)
      // }
    }
  }

  const productClick = (data) => {
    // dispatch(dataValue({ 'active_product': data }))
    // setSchemaData({ ...schemaData, 'active_product': data })
  }


  const updateCartFn = (data, val) => {
    Api.PutApiFE(`${config.CART}/${val.cart_item_id}`, data).then(async res => {
      setTimeout(() => {
        setloadingBtn(false)
      }, 1000);
      setQuantity({})
      console.log("res add to cart: ", res)
      if (res.error === true) {
        snack(res.response.data.error, 'error');
      } else {
        if (res.data.data) {
          getUpdatedCartFn()
        }
      }
    })
  }
  //update quantity
  const updateCart = (type, val) => {
    console.log("updateCart: ", val)
    setloadingBtn(true)
    let data = {}
    // let data = {
    //   quantity: type == "inc" ? parseInt(val.quantity) + 1 : parseInt(val.quantity) - 1
    // }
    if (type == "inc") {
      if (!val?.product_variant?.inventory_tracked
        || (val?.product_variant?.inventory_tracked == true && val?.product_variant?.out_of_stock)
        || (val?.product_variant?.inventory_tracked == true && !val?.product_variant?.out_of_stock && val?.product_variant?.inventory?.available > val?.quantity)
        || (!val?.product_variant?.inventory_tracked && !val?.product_variant?.out_of_stock)
      ) {
        data = {
          quantity: parseInt(val.quantity) + 1
        }
      }
    } else {
      data = {
        quantity: parseInt(val.quantity) - 1
      }
    }

    console.log("updateCart data: ", data)
    if (!_.isEmpty(data)) {
      updateCartFn(data, val)
    } else {
      snack("You can't add more items.", 'error');
      setloadingBtn(false)
    }
  }


  const handleQtyChange = (e, data) => {
    if (e.target.value <= 10000) {
      setQuantity({ ...quantity, value: e.target.value || 1, data: data })
    } else {
      setQuantity({})
    }
  }
  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!_.isEmpty(quantity)) {
        let data = {
          quantity: parseInt(quantity.value)
        }
        updateCartFn(data, quantity.data)
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [quantity]);

  //Delete cart item
  const removeItem = (data) => {
    console.log("remove item cart", data)
    Api.DeleteApiFE(`${config.CART}/${data?.cart_item_id}`).then(res => {
      console.log("res delete item: ", res)
      if (res.error === true) {
        snack(res.response.data.error, 'error');
      } else {
        if (res.data.data && dataVal && dataVal?.store_id) {
          snack(res.data.data, 'success');

          let arr = []
          arr.push({
            item_id: data?.product_variant_id,
            item_name: data?.product_variant?.Product?.title || "",
            affiliation: dataVal && dataVal?.store_name || "",
            item_variant: data?.product_variant?.title !== "Default Title" ? data?.product_variant?.title : data?.product_variant?.Product?.title || "",
            price: data?.product_variant?.price || "0",
            quantity: data?.quantity || 1
          })

          let data2 = {
            currency: config?.CURRENCY_CODE,
            value: data?.product_variant?.price * data?.quantity || "0",
            product_id: data?.product_variant?.Product?.product_id || "",
            variant_id: data?.product_variant_id,
            items: arr
          }
          console.log("remove_from_cart", data2)
          ReactGA.event("remove_from_cart", data2);
          ReactPixel.track("RemoveFromCart", data2);

          getUpdatedCartFn()
        }
      }
    })
  }
  const checkMaintenenceMode = (store_id) => {
    Api.GetApiFE(config.MAINTENANCE_MODE).then(res => {
      if (res.error === true) {
        snack(res.response.data.error, 'error');
        setMaintenance({ maintenance_mode: false })
      } else {
        let bodyparam = {
          status: "active"
        }
        storeProducts(Api.PostApiFE, config.CUSTOMER_PRODUCT_LIST, "", bodyparam)
        if (res.data.data) {
          if (res.data.data?.maintenance_mode) {
            dispatch(dataValue({ 'maintenance': res.data.data }))
            setSchemaData({ ...schemaData, 'maintenance': res.data.data })
          } else {
            // getCartCookies(store_id)
          }
          setMaintenance(res.data.data)
        } else {
          // getCartCookies(store_id)
          setMaintenance({ maintenance_mode: false })
        }

        Api.GetApiFE(config.CUSTOMER_PREFERENCE).then(resp => {
          if (resp.error === true) {
            snack(resp.response.data.error, 'error');
          } else {
            if (res.data.data) {
              setPreferences(resp.data.data)
              dispatch(dataValue({ 'preferences': res.data.data }))
              if (resp.data.data?.google_Analytics) {
                ReactGA.initialize(resp.data.data?.google_Analytics);
              }
              if (resp.data.data?.facebook_Pixel) {
                ReactPixel.init(resp.data.data?.facebook_Pixel);
                ReactPixel.pageView()
              }
            }
          }
        })
      }
    })
  }
  console.log("preferences: ", preferences)

  const getTemplates = (type, id, email, path) => {
    let api = "",
      url = "",
      menu_url = "",
      category_url = "",
      post_url = ""
    if (searchParams.get("store_demo")) {
      id = searchParams.get("store_demo")
      Api.setCookies("theme_id", searchParams.get("store_demo"))
    } else if (email == process.env.REACT_APP_THEME_EMAIL && Api.getCookies()?.store_demo) {
      id = Api.getCookies()?.store_demo
    }
    //  else {
    //   Api.removeThemeCookies()
    // }


    console.log("type, id, email", type, "---------", id, "-----------", email)

    if (type == "admin") {
      api = Api.GetApi
      url = `${config.TEMPLATE_DETAIL}/${id}`
      menu_url = config.MENU
      category_url = config.PRODUCT_CATEGORY_LIST
      post_url = config.BLOG_POST_PUBLISH
      Api.removeThemeCookies()
    } else {
      api = Api.GetApiFE
      url = `${config.TEMPLATE}/${id}`
      menu_url = config.CUSTOMER_MENU
      category_url = config.CUSTOMER_CATEGORY_LIST
      post_url = `${config.CUSTOMER_BLOG_POST}/list`
    }
    console.log("first", url)
    api(url).then(res => {
      console.log("template res", res)
      if (res.error === true) {
        snack(res.response.data.error, 'error');
        dispatch(dataValue({ 'active_page': "home" }))
        setSchemaData({ ...schemaData, 'active_page': "home" })
        setPageLoad(true)
      } else {
        setTimeout(() => {
          setPageLoad(true)
        }, 1000);
        if (res.data.data) {
          let menuList = []
          // if (!res.data.data?.is_draft && type !== "admin" && Api.getCookies()?.theme_id) {
          if ((res.data.data?.active || res.data.data?.is_active) && type !== "admin" && Api.getCookies()?.theme_id) {
            ////// getTemplates("", process.env.REACT_APP_DEFAULT_THEME_ID, "")
            // Api.removeThemeCookies()
            dispatch(dataValue({ "updateTheme": "draft" }))
          }

          api(menu_url).then(resp => {
            if (resp.error === true) {
              // snack(resp.response.data.error, 'error');
            } else {
              if (resp.data.data) {
                resp.data.data.length > 0 && resp.data.data.map((opt) => {
                  menuList.push({ name: opt?.title, value: opt?.id, data: opt })
                })

                let resData = {}
                console.log("email------------------", email, "====", process.env.REACT_APP_THEME_EMAIL)
                if (searchParams.get("store_demo") || email == process.env.REACT_APP_THEME_EMAIL) {
                  resData = res.data.data?.default_theme_json
                } else {
                  resData = res.data.data?.theme_json || res.data.data?.default_theme_json
                }
                let common = resData?.common
                if (common) {
                  common["footer"]["limit"] = 4
                }
                let products = {
                  ...resData?.product_detail,
                }
                let category_list_page = {
                  ...resData?.category_list_page,
                }
                let category_page = {
                  ...resData?.collection,
                }
                if (!resData?.category_list_page) {
                  category_list_page = {
                    product_type: true,
                    vendor: true,
                    product_tag: true,
                    banner_position: true,
                    show_title: true,
                    show_banner: true,
                    show_description: true,
                    banner_position: "fixed",
                  }
                }
                if (resData?.product_detail["active"]) {
                  products = {
                    show_product_type: true,
                    show_vender: true,
                    show_sku: true,
                    text_color: "",
                    description_position: "fixed",
                    option_type: "select"
                  }
                  delete products["active"]
                }

                let objData = {
                  ...dataVal,
                  menuList: menuList,
                  active_page: "home",
                  theme_id: res.data.data?.id,
                  theme_name: res.data.data?.theme_name,
                  common: common,
                  components: res.data.data?.components,
                  // components: resData?.components,
                  collection: resData?.collection,
                  home: resData?.home,
                  product_detail: products,
                  category_list_page: category_list_page,
                  category_page: category_page,
                }
                dispatch(dataValue({ ...objData }))
                setSchemaData({
                  ...schemaData,
                  ...objData
                })

                dispatch(dataValue({ "menuList": menuList }))
              }
            }
          })
          api(category_url).then(res2 => {
            console.log("res STORE filters-----: ", res)
            if (res2.error === true) {
              snack(res2.response.data.error, 'error');
            } else {
              if (res2.data.data) {
                setFilterList(res2.data.data)
              }
            }
          })
          api(post_url).then(res2 => {
            if (res2.error === true) {
              snack(res2.response.data.error, 'error');
            } else {
              if (res2.data.data) {
                let resData = []
                let current = new Date()
                res2.data.data.rows.length > 0 && res2.data.data.rows.map((opt) => {
                  let visibility_date = new Date(opt?.visibility_date)
                  if (opt?.visibility && current > visibility_date) {
                    resData.push(opt)
                  }
                })
                setBlogList(resData)
                dispatch(dataValue({ "blogList": resData }))
              }
            }
          })
        }
      }
    })
  }

  console.log("blogList: ", blogList)

  const handlePageChange = useCallback((e) => {
    // setSchemaData({ ...schemaData, 'active_page': e.target.value })
    dispatch(dataValue({ 'active_page': e.target.value }))
  }, [])


  const updateTemplate = (id) => {
    setLoadingSaveButton(true)
    console.log("useparam: ", id)
    if (dataVal && dataVal?.common && dataVal?.home && dataVal?.product_detail && dataVal?.collection) {
      let data = {
        theme_json: {
          common: dataVal["common"],
          home: dataVal["home"],
          product_detail: dataVal["product_detail"],
          category_list_page: dataVal["category_list_page"],
          collection: dataVal["collection"],
          component: dataVal["components"],
        }
      }

      Api.PutApi(`${config.TEMPLATE}/${id}`, data).then(async res => {
        console.log("res update template: ", res)
        setLoadingSaveButton(false)
        if (res.error === true) {
          snack(res.response.data.error, 'error');
        } else {
          if (res.data.data) {
            snack("Updated sucessfully", 'success');
            dispatch(dataValue({ "update": "" }))

            if (res.data.data?.theme_id) {
              const url = new URL(window.location.href);
              url.pathname = url.pathname.replace(/\/admin\/customizer\/(\d+)/, `/admin/customizer/${res.data.data?.theme_id}`);
              const updatedUrl = url.toString();
              console.log("updatedUrl", updatedUrl);
              // window.location.replace(updatedUrl);
              window.history.replaceState(null, '', updatedUrl);
            }
            // let dataRes = {...dataVal}
            // let resData = res.data.data
            // let objData = {
            //   ...dataRes,
            //   active_page: dataVal?.active_page,
            //   theme_id: resData?.id,
            //   common: resData?.common,
            //   collection: resData?.collection,
            //   home: resData?.home,
            //   product_detail: resData?.product_detail,
            // }
            // dispatch(dataValue({ ...objData }))
            // setSchemaData({
            //   ...schemaData,
            //   ...objData
            // })
          }
        }
      })
    }
  }



  useEffect(() => {
    // updateTemplate()
  }, [dataVal?.update])

  const getBlankCartToken = (id, store_id, type, email) => {
    if (localStorage.getItem("locData") && !_.isEmpty(JSON.parse(localStorage.getItem("locData")))) {
      let loc_data = JSON.parse(localStorage.getItem("locData"))
      if (loc_data?.latitude && loc_data?.longitude && loc_data?.city && loc_data?.state) {
        getCartTokenFn(id, store_id, `?latitude=${loc_data?.latitude}&longitude=${loc_data?.longitude}&city=${loc_data?.city || "NA"}&state=${loc_data?.state || "NA"}`, type, email)
      } else {
        getCartTokenFn(id, store_id, `?latitude=${loc_data?.latitude}&longitude=${loc_data?.longitude}`, type, email)
      }
    } else {
      // navigator.geolocation.getCurrentPosition(function (position) {
      //   getCartTokenFn(store_id, `?latitude=${position.coords?.latitude}&longitude=${position.coords?.longitude}`, type)
      // }.bind(this),
      //   function (error) {
      //     getCartTokenFn(store_id, "", "")
      //   })
      getData(id, store_id, type, email)
    }
  }
  const getData = async (id, store_id, type, email) => {
    await axios.get("https://ipinfo.io/json", { timeout: 5000 }).then(res => {
      console.log("getData res:", res)
      if (res.status == 200) {
        const expires = new Date();
        expires.setDate(moment.utc(Date.now() + 1000 * 60 * 60 * 24).format());
        let option = {
          expires,
          path: "/",
          maxAge: 3600,
        };
        cookie.save("user_loc", true, option);
        let latitude = ""
        let longitude = ""
        if (res.data.loc.split(",").length) {
          latitude = res.data.loc.split(",")[0]
          longitude = res.data.loc.split(",")[1]
        }
        console.log("getData res latitude:", latitude)
        console.log("getData res longitude:", longitude)
        if (latitude !== "Not found" && longitude !== "Not found" && res?.data?.city !== "Not found" && res?.data?.state !== "Not found") {
          getCartTokenFn(id,
            store_id,
            Api.isTokenFE().token ?
              `?authtoken=${Api.isTokenFE().token}&latitude=${latitude && latitude !== "null" ? latitude : "NA"}&longitude=${longitude && longitude !== "null" ? longitude : "NA"}&city=${res?.data?.city && res?.data?.city !== "null" ? res?.data?.city : "NA"}&state=${res?.data?.state && res?.data?.state !== "null" ? res?.data?.state : "NA"}`
              : `?latitude=${latitude && latitude !== "null" ? latitude : "NA"}&longitude=${longitude && longitude !== "null" ? longitude : "NA"}&city=${res?.data?.city && res?.data?.city !== "null" ? res?.data?.city : "NA"}&state=${res?.data?.state && res?.data?.state !== "null" ? res?.data?.state : "NA"}`,
            type, email
          )
          let resLocData = { ...res.data }
          resLocData["latitude"] = latitude
          resLocData["longitude"] = longitude
          localStorage["locData"] = JSON.stringify(resLocData)
        } else {
          getCartTokenFn(id, store_id, Api.isTokenFE().token ? `?authtoken=${Api.isTokenFE().token}` : "", "", email)
        }
      } else {
        getCartTokenFn(id, store_id, "", type, email)
      }
    }).catch((err) => {
      getCartTokenFn(id, store_id, "", type, email)
    })
  };
  const getCartCookies = (store_id, id, email) => {
    console.log("loc", location)
    // MAINTENANCE_MODE
    // if (!Api.isTokenFE().isToken) {
    //   if (store_id) {
    //     getBlankCartToken(store_id, "")
    //   }
    // }
    // else {
    if (store_id && !location.startsWith("/admin")) {
      // if (localStorage.getItem("locData") && !_.isEmpty(JSON.parse(localStorage.getItem("locData")))) {
      //   let loc_data = JSON.parse(localStorage.getItem("locData"))
      //   if (loc_data?.latitude && loc_data?.latitude !== "" && loc_data?.longitude && loc_data?.longitude !== "") {

      //     getData(store_id)
      //   }
      // } else {
      //   navigator.geolocation.getCurrentPosition(function (position) {
      //     console.log("Latitude is :", position.coords.latitude);
      //     console.log("Longitude is :", position.coords.longitude);
      //     if (position && position?.coords) {
      //       let data = {
      //         "latitude": position?.coords?.latitude,
      //         "longitude": position?.coords?.longitude,
      //         "store_id": store_id
      //       }
      //       Api.PostApiFE(config.CUSTOMER_LOCATION, data).then(async res => {
      //         console.log("res update template: ", res)
      //         if (res.error === true) {
      //           snack(res.response.data.error, 'error');
      //         } else {
      //           if (res.data.data) {
      //             localStorage["locData"] = JSON.stringify(res.data.data)
      //             getCartTokenFn(store_id, `?authtoken=${Api.isTokenFE().token}&latitude=${position?.coords?.latitude}&longitude=${position?.coords?.longitude}`, "")
      //           }
      //         }
      //       })
      //     }
      //   }.bind(this),
      //     function (error) {
      //       getCartTokenFn(store_id, `?authtoken=${Api.isTokenFE().token}`, "")
      //     })
      // }


      if (Api.getCookies()?.user_loc) {
        if (localStorage.getItem("locData") && !_.isEmpty(JSON.parse(localStorage.getItem("locData")))) {
          let loc_data = JSON.parse(localStorage.getItem("locData"))
          if (loc_data?.latitude && loc_data?.longitude && loc_data?.city && loc_data?.state) {
            getCartTokenFn(id, store_id, `?authtoken=${Api.isTokenFE().token}&latitude=${loc_data?.latitude}&longitude=${loc_data?.longitude}&city=${loc_data?.city || "NA"}&state=${loc_data?.state || "NA"}`, "", email)
          } else {
            getCartTokenFn(id, store_id, Api.isTokenFE() ? `?authtoken=${Api.isTokenFE().token}` : "", "", email)
          }
        } else {
          getCartTokenFn(id, store_id, "", "", email)
          getData(id, store_id, "", email)
        }
      } else {
        getData(id, store_id, "", email)
      }

    }
    // }
  }

  console.log("get cookies", Api.getCookies())


  useEffect(() => {
    if (!location.startsWith("/admin") && Api.isTokenFE().token) {
      getUpdatedCartFn()
    }
  }, [localStorage["session_token"]])

  const proceedCheckout = () => {
    // if (Api.isTokenFE().token) {
    //   window.open(`http://localhost:3003?token=${Api.isTokenFE().token}&type=cart&auth_type=${Api.isLogInFE().user_type}&ga=${preferences?.google_Analytics || "NA"}&fbp=${preferences?.facebook_Pixel || "NA"}`, '_self')
    // } else {
    //   window.open(`http://localhost:3003?token=${Api.isTokenFE().token}&type=cart&logout=1&ga=${preferences?.google_Analytics || "NA"}&fbp=${preferences?.facebook_Pixel || "NA"}`, '_self')
    // }

    if (Api.isTokenFE().token) {
      window.open(`https://pay.${Api.appURL}?token=${Api.isTokenFE().token}&type=cart&auth_type=${Api.isLogInFE().user_type}&ga=${preferences?.google_Analytics || "NA"}&fbp=${preferences?.facebook_Pixel || "NA"}`, '_self')
    } else {
      window.open(`https://pay.${Api.appURL}?token=${Api.isTokenFE().token}&type=cart&logout=1&ga=${preferences?.google_Analytics || "NA"}&fbp=${preferences?.facebook_Pixel || "NA"}`, '_self')
    }
  }


  //--------------------//for Live
  useEffect(() => {
    if (location.startsWith("/admin")) {
      if (location !== "/admin/inactive-store") {
        if (Api.isLogIn().token) {
          getStoreDetail("live")
        }
      }
    } else {
      let data = {
        domain: window.location.host
      }
      if (searchParams.get("theme_id") && searchParams.get("theme_id") !== "active") {
        Api.setCookies("theme_id", searchParams.get("theme_id"))
        searchStoreDetail(data, searchParams.get("theme_id"))
      } else if (searchParams.get("theme_id") == "active") {
        Api.removeThemeCookies()
        setTimeout(() => {
          searchStoreDetail(data, "")
        }, 100);

      } else {
        searchStoreDetail(data, Api.getCookies()?.theme_id)
      }
    }
  }, [Api.isLogIn().token])



  //-----------------//For localhost     
  // useEffect(() => {
  //   dispatch(dataValue({ 'store_id': "4440645722266", store_name: "Weo test store" }))

  //   if (location.startsWith("/admin")) {
  //     if (location !== "/admin/inactive-store") {
  //       if (Api.isLogIn().token) {
  //         getStoreDetail("local")
  //       }
  //     }
  //   } else {
  //     let data = {
  //       domain: `demo-store1.${Api.appMainURL}`
  //     }
  //     if (searchParams.get("theme_id") && searchParams.get("theme_id") !== "active") {
  //       Api.setCookies("theme_id", searchParams.get("theme_id"))
  //       searchStoreDetail(data, searchParams.get("theme_id"))
  //     } else if (searchParams.get("theme_id") == "active") {
  //       Api.removeThemeCookies()
  //       setTimeout(() => {
  //         searchStoreDetail(data, "")
  //       }, 100);

  //     } else {
  //       searchStoreDetail(data, Api.getCookies()?.theme_id)
  //     }
  //   }
  // }, [Api.isLogIn().token])


  function logout() {
    localStorage["user_type"] = "GUEST"
    setTimeout(() => {
      // getCartTokenFn(dataVal && dataVal?.store_id, "", "token")
      getBlankCartToken(themeId, dataVal && dataVal?.store_id, "token")
    }, 500);
  }


  React.useEffect(() => {
    window.onpopstate = e => {
      setPressed(!pressed)
    };
  });

  const hideBar = () => {
    let bottom_bar = document.querySelector(".bottom_bar")
    if (bottom_bar) {
      bottom_bar.classList.add("hide")
    }
  }
  const closePreview = () => {
    searchParams.delete("theme_id")
    Api.removeThemeCookies()
    setTimeout(() => {
      window.open("/", "_self")
    }, 500);
  }

  console.log("pressed: ", pressed)
  console.log("storeData: ", storeData)
  console.log("maintenance: ", maintenance)
  console.log("dataVal: ", dataVal)
  console.log("schemaData: ", schemaData)
  console.log("location schemaData: ", location)
  console.log("preferences: ", preferences)

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={renderLoader()}>
        <Box sx={{ display: 'flex' }}>
          {expireStore ?
            <div className='expireStore'>
              <div>
                <img src={noStore} alt='' width={150} className='mb-4' />
                <h3>This store has been expired.</h3>
                {storeDataFE?.store_email ?
                  <p>Please contact admin: <a href={`mailto:${storeDataFE?.store_email}`} style={{ color: "red" }}>{storeDataFE?.store_email}</a></p>
                  : null
                }
              </div>
            </div>
            :
            <>
              <Helmet>
                {!_.isEmpty(dataVal) &&
                  <style>
                    {`
                :root{
                    --fontFamily: ${dataVal?.common?.typography?.body_font || "Roboto"};
                    --fontSize: ${`${dataVal?.common?.typography?.body_text_base_size}px`};
                    --heading_font: ${dataVal?.common?.typography?.heading_font || "Roboto"};
                    --heading_font_weight: ${dataVal?.common?.typography?.heading_font_weight || "700"};
                    --body_font_weight: ${dataVal?.common?.typography?.body_font_weight || "400"};
                    --button_font_weight: ${dataVal?.common?.typography?.button_font_weight || "400"};
                    --heading_size: ${`${dataVal?.common?.typography?.heading_base_size}px`};
                    --button_radius: ${`${dataVal?.common?.typography?.button_radius || 0}px`};
                    --button_spacing_y: ${`${dataVal?.common?.typography?.button_spacing_y || 10}px`};
                    --button_spacing_x: ${`${dataVal?.common?.typography?.button_spacing_x || 10}px`};
                    --heading_color: ${dataVal?.common?.colors?.heading_color};
                    --color: ${dataVal?.common?.colors?.body_text_color};
                    --bg: ${dataVal?.common?.colors?.body_bg_color};
                    --buttonColor: ${dataVal?.common?.colors?.button_color};
                    --button_border_color: ${dataVal?.common?.colors?.button_border_color};
                    --button_hover_border_color: ${dataVal?.common?.colors?.button_hover_border_color};
                    --buttonHover: ${dataVal?.common?.colors?.button_hover_color};
                    --buttonHoverText: ${dataVal?.common?.colors?.button_text_hover_color || dataVal?.common?.colors?.button_text_color};                            
                    --buttonText: ${dataVal?.common?.colors?.button_text_color};
                    --footer_text_color: ${dataVal?.common?.footer?.footer_text_color || "#000000"};
                    --overlay_text_color: ${dataVal?.common?.colors?.overlay_text_color || "#ffffff"};
                    --header_color: ${dataVal?.common?.header?.header_color || "#ffffff"};
                    --dm_header_color: ${dataVal?.common?.header?.dm_header_color || "#000000"};
                    --dm_header_text_color: ${dataVal?.common?.header?.dm_header_text_color || "#ffffff"};
                    --dm_bar_color: ${dataVal?.common?.header?.dm_bar_color || "#000000"};
                    --dm_bar_text_color: ${dataVal?.common?.header?.dm_bar_text_color || "#ffffff"};
                    --header_text_color: ${dataVal?.common?.header?.header_text_color || "#000000"};
                    --product_text_color: ${dataVal?.product_detail?.text_color || dataVal?.common?.colors?.body_text_color || "#000000"};
                    --bar_color: ${dataVal?.common?.header?.bar_color || "#333"};
                    --bar_text_color: ${dataVal?.common?.header?.bar_text_color || "#ffffff"};
                    --overlay_bg: ${dataVal?.common?.colors?.overlay_bg_color || "#000"};
                    --overlay_opacity: ${dataVal?.common?.colors?.overlay_opacity / 100 || "0.5"};
                }
                @media (prefers-color-scheme: dark) {
                  :root{
                      --header_color: #161616;
                      --header_text_color: #ffffff;
                      --bar_color: #333;
                      --bar_text_color: #ffffff;
                      --footer_text_color: #ffffff;
                      --heading_color: #ffffff;
                      --color: #ffffff;
                      --bg: #000000;
                      --buttonColor: #ffffff;
                      --button_border_color: #ffffff;
                      --button_hover_border_color: #ffffff;
                      --buttonHover: #ffffff;
                      --buttonHoverText: #000000;                            
                      --buttonText: #000000;
                      --overlay_text_color: #ffffff;
                      --product_text_color: #ffffff;
                  }
                }
                `
                    }
                  </style>
                }

                <title>{preferences?.title || (dataVal && dataVal?.store_name) || dataVal?.store?.store_name || ""}</title>
                <meta name="description" content={preferences?.meta_description || ""} />
                <meta name="apple-mobile-web-app-title" content={preferences?.title || (dataVal && dataVal?.store_name) || ""} />
                <meta name="twitter:title" content={preferences?.title || (dataVal && dataVal?.store_name) || ""} />
                <meta name="twitter:description" content={preferences?.meta_description || ""} />

                <meta name="twitter:image" content={preferences?.social_sharing_image || ""} />
                <meta itemprop="image" content={preferences?.social_sharing_image || ""} />
                <link rel="apple-touch-icon" href={preferences?.social_sharing_image || ""} />
                <meta property="og:image" content={preferences?.social_sharing_image || ""} />
                <meta property="og:image" name="og:image" content={preferences?.social_sharing_image || ""} />
                {/* {preferences?.google_Analytics ?
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${preferences?.google_Analytics}`}
            /> : null
          }
          {preferences?.google_Analytics ?
            <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', ${preferences?.google_Analytics});
            `}
            </script>
            : null
          } */}
              </Helmet>

              {!location.startsWith("/admin") ?
                <div className='template_main'>
                  <Helmet>
                    <link rel="icon" href={(dataVal && dataVal?.common && dataVal?.common?.favicon && dataVal?.common?.favicon?.image_scr) || ""} />
                  </Helmet>
                  {!pageLoad ?
                    <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                    :
                    <Router>
                      <ScrollToTop />
                      {!_.isEmpty(dataVal) && dataVal?.common && !maintenance?.maintenance_mode &&
                        <BlockRenderFn
                          editor={false}
                          type={"header"}
                          data={dataVal?.common["header"]}
                          proceedCheckout={proceedCheckout}
                          removeItem={removeItem}
                        />
                      }

                      <Routes>
                        {!_.isEmpty(maintenance) &&
                          <React.Fragment>
                            <Route path="*" element={<NoPageFE link="/" />} exact />
                            {maintenance?.maintenance_mode ?
                              <Route path="/" element={<Maintenance
                                data={maintenance}
                                dataVal={dataVal}
                              />} exact />
                              :
                              <React.Fragment>
                                <Route element={<PrivateRouteFE />}>
                                  <Route path="/myaccount" element={<MyAccount logout={logout} />} exact />
                                  <Route path="/myaccount/edit" element={<EditProfile />} exact />
                                  <Route path="/myaccount/address" element={<Address />} exact />
                                  <Route path="/myaccount/address/edit/:id" element={<EditAddress />} exact />
                                  <Route path="/myaccount/address/add" element={<EditAddress />} exact />
                                  <Route path="/myaccount/orders" element={<AccoutOrders />} exact />
                                  <Route path="/myaccount/orders/:id" element={<ViewAccoutOrders />} exact />
                                  <Route path="/myaccount/orders/:id/:item" element={<ViewOrderItem />} exact />
                                </Route>
                                <Route path="/my-orders/:id" element={<ViewAccoutOrders />} exact />
                                <Route element={<PublicRouteFE />} exact>
                                  <Route path="/login" element={<Login />} exact />
                                  <Route path="/signup" element={<Register />} exact />
                                  <Route path="/forgot-password" element={<ForgotPassword />} exact />
                                  <Route path="/forgot" element={<ResetPassword />} exact />
                                </Route>
                                <Route path="/reset-password" element={<ResetPassword />} exact />
                                <Route path="/verify" element={<Verify />} exact />
                                <Route path="/" element={<LandingPage
                                  addToCartFn={addToCartFn}
                                  fetchMoreData={fetchMoreData}
                                  showLoader={showLoader}
                                  hasNoMoreData={hasNoMoreData}
                                />} exact />
                                <Route path={`${config.PRODUCTS}`} element={<ProductListPage
                                  addToCartFn={addToCartFn}
                                  fetchMoreData={fetchMoreData}
                                  showLoader={showLoader}
                                  hasNoMoreData={hasNoMoreData}
                                />} exact />
                                <Route path={`${config.CATEGORIES}`} element={<CollectionList
                                  fetchMoreData={fetchMoreData}
                                  showLoader={showLoader}
                                  hasNoMoreData={hasNoMoreData}
                                />} exact />
                                <Route path={`${config.CATEGORIES}/:id`} element={<Collection
                                  data={filterList}
                                  addToCartFn={addToCartFn}
                                  fetchMoreData={fetchMoreData}
                                  showLoader={showLoader}
                                  hasNoMoreData={hasNoMoreData}
                                />} exact />
                                <Route path="/mycart" element={<Cart
                                  updateCart={updateCart}
                                  handleQtyChange={handleQtyChange}
                                  loadingBtn={loadingBtn}
                                  removeItem={removeItem}
                                  quantity={quantity}
                                  proceedCheckout={proceedCheckout}
                                />} exact />
                                <Route path={`${config.PRODUCTS}/:id`} element={<ProductTheme
                                  addToCartFn={addToCartFn}
                                  productClick={productClick}
                                  fetchMoreData={fetchMoreData}
                                  showLoader={showLoader}
                                  hasNoMoreData={hasNoMoreData}
                                />} exact />
                                <Route path="/blogs" element={<BlogList />} exact />
                                <Route path="/blogs/:id" element={<BlogsDetail />} exact />
                                <Route path="/pages/:id" element={<PagesFE />} exact />
                                <Route path="/thank-you" element={<Thankyou />} exact />
                              </React.Fragment>
                            }
                          </React.Fragment>
                        }
                      </Routes>
                      {!_.isEmpty(dataVal) && dataVal?.common && !maintenance?.maintenance_mode &&
                        <BlockRenderFn
                          editor={false}
                          type={"footer"}
                          data={dataVal?.common["footer"]}
                        />
                      }


                      {(searchParams.get("theme_id") && (searchParams.get("theme_id") !== "active")) || (Api.getCookies()?.theme_id && (dataVal?.store_email && dataVal?.store_email !== process.env.REACT_APP_THEME_EMAIL)) || (Api.getCookies()?.theme_id && dataVal?.updateTheme !== "draft") ?
                        <div className="bottom_bar">
                          <p><small>You are previewing <strong>{dataVal && dataVal?.theme_name || ""}</strong></small></p>
                          <div className="bottom_bar_btn">
                            <button className="custom_btn custom_btn-primary btn-sm" onClick={closePreview}>Close preview</button>
                            <button className="custom_btn custom_btn-outline-primary btn-sm ms-2" onClick={hideBar}>Hide bar</button>
                          </div>
                        </div>
                        : null
                      }
                    </Router>
                  }
                </div>
                :
                location == "/admin/inactive-store" ?
                  <Router>
                    <Helmet>
                      <script defer src="https://checkout.razorpay.com/v1/checkout.js"></script>
                      <script type='text/javascript' src='https://ind-widget.freshworks.com/widgets/1070000000552.js' async defer></script>
                    </Helmet>
                    <Routes>
                      <Route path="/admin/inactive-store" element={<InactiveStore />} exact />
                    </Routes>
                  </Router>
                  :
                  <Router>
                    <ScrollToTop />
                    {!location.includes("/customizer") &&
                      <>
                        <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
                        <Header handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} storeData={storeData} />
                      </>
                    }
                    <Box
                      component="main"
                      className='main_outer'
                      sx={{
                        flexGrow: 1,
                        // p: 3, 
                        p: { xs: 3, lg: 3 },
                        px: { xs: 0, lg: 3 },
                        width: { sm: `calc(100% - ${drawerWidth}px)` }
                      }}>

                      <Helmet>
                        <script type='text/javascript' src='https://ind-widget.freshworks.com/widgets/1070000000552.js' async defer></script>
                      </Helmet>
                      {location == "/admin/settings/plan" ?
                        <Helmet>
                          <script defer src="https://checkout.razorpay.com/v1/checkout.js"></script>
                        </Helmet>
                        : null
                      }
                      <Box className='main_block'>
                        <Toolbar />
                        <Routes>
                          <Route path="*" element={<NoPage link="/admin" />} exact />
                          <Route exact path='/' element={<PrivateRoute />}>
                            <Route path="/admin" element={<Home storeData={storeData} />} exact />
                            <Route path={`/admin${config.PRODUCTS}/list`} element={<Product />} exact />
                            <Route path={`/admin${config.PRODUCTS}/add`} element={<AddProduct />} exact />
                            <Route path={`/admin${config.PRODUCTS}/:id`} element={<AddProduct />} exact />
                            <Route path={`/admin${config.PRODUCTS}/:id/variant/:vid`} element={<AddVariant />} exact />
                            <Route path={`/admin${config.PRODUCTS}/inventory`} element={<Inventory />} exact />
                            <Route path={`/admin${config.PRODUCTS}/inventory-history/:id`} element={<InventoryHistory />} exact />
                            <Route path={`/admin${config.PRODUCTS}${config.CATEGORIES}`} element={<Category />} exact />
                            <Route path={`/admin${config.PRODUCTS}${config.CATEGORIES}/add`} element={<AddCategory />} exact />
                            <Route path={`/admin${config.PRODUCTS}${config.CATEGORIES}/:id`} element={<AddCategory />} exact />

                            <Route path={`/admin${config.PRODUCTS}/gift-card/add`} element={<AddGiftProduct />} exact />
                            <Route path={`/admin/gift-card/add`} element={<AddGiftCard />} exact />
                            <Route path={`/admin/gift-card`} element={<GiftCards />} exact />
                            <Route path={`/admin/gift-card/:id`} element={<EditGiftCard />} exact />

                            <Route path="/admin/discount" element={<Discount />} exact />
                            <Route path="/admin/discount/add" element={<AddDiscount />} exact />
                            <Route path="/admin/discount/:id" element={<AddDiscount />} exact />

                            <Route path="/admin/reports" element={<Reports />} exact />
                            <Route path="/admin/reports/products" element={<ReportsProducts />} exact />
                            <Route path="/admin/reports/products/:id" element={<ReportsProductVariant />} exact />
                            <Route path="/admin/reports/location" element={<ReportsLocation />} exact />
                            <Route path="/admin/reports/discounts" element={<ReportsDiscount />} exact />
                            <Route path="/admin/reports/customers" element={<ReportsCustomers />} exact />

                            <Route path="/admin/customizer/:id" element={<Layout
                              // data={dataVal}
                              handlePageChange={handlePageChange}
                              updateTemplate={updateTemplate}
                              loadingSaveButton={loadingSaveButton}
                              themeId={themeId}
                            />} exact />
                            {/* <Route path="/admin/customizer/1" element={<Template1 />} exact /> */}

                            <Route path="/admin/profile" element={<Profile />} exact />

                            <Route path="/admin/users" element={<User />} exact />
                            <Route path="/admin/users/add" element={<AddUser />} exact />
                            <Route path="/admin/users/:id" element={<EditUser />} exact />

                            <Route path="/admin/settings/details" element={<StoreDetails />} exact />
                            <Route path="/admin/settings/plan" element={<Plan storeData={storeData} />} exact />
                            <Route path="/admin/settings/billing" element={<Billing />} exact />
                            <Route path="/admin/settings/permission" element={<UserAndPermission />} exact />
                            <Route path="/admin/settings/payments" element={<Payments />} exact />
                            <Route path="/admin/settings/email/templates" element={<Notifications />} exact />
                            <Route path="/admin/settings/email/variables" element={<VariablesDoc />} exact />
                            <Route path="/admin/settings/email/edit/:id" element={<CustomizeEmail />} exact />
                            <Route path="/admin/settings/email/:id" element={<PreviewEmail />} exact />
                            <Route path="/admin/settings/shipping" element={<ShipingMethods />} exact />
                            <Route path="/admin/settings/domain-verification" element={<DomainVerification />} exact />
                            {/* <Route path="/admin/settings/taxes" element={<Taxes />} exact /> */}
                            <Route path="/admin/settings/taxes" element={<EditTaxes />} exact />

                            <Route path="/admin/orders" element={<Orders />} exact />
                            <Route path="/admin/orders/returns" element={<Exchange />} exact />
                            <Route path="/admin/orders/returns/:id/:item" element={<ExchaneOrderDetail />} exact />
                            <Route path="/admin/orders/draft" element={<DraftOrder />} exact />
                            <Route path="/admin/orders/draft/:id" element={<CreateOrder />} exact />
                            <Route path="/admin/orders/draft/create" element={<CreateOrder />} exact />
                            <Route path="/admin/orders/create/:item" element={<CreateOrder />} exact />
                            <Route path="/admin/orders/:id" element={<OrderDetail />} exact />
                            <Route path="/admin/orders/refund/:id" element={<Refund />} exact />
                            <Route path="/admin/orders/details/restock" element={<Restock />} exact />
                            <Route path="/admin/orders/details/return" element={<Return />} exact />
                            <Route path="/admin/orders/checkouts" element={<AbandonedCheckout />} exact />
                            <Route path="/admin/orders/checkouts/:id" element={<AbandonedDetail />} exact />

                            <Route path="/admin/store/templates" element={<TemplatesView />} exact />
                            {/* <Route path="/admin/store/templates/view" element={ <TemplatesView />} exact /> */}

                            <Route path="/admin/store/blogs" element={<Blogs />} exact />
                            <Route path="/admin/store/blogs-category" element={<BlogCategory />} exact />
                            <Route path="/admin/store/blogs-category/add" element={<AddBlogCategory />} exact />
                            <Route path="/admin/store/blogs/add" element={<AddBlog />} exact />
                            <Route path="/admin/store/blogs/:id" element={<AddBlog />} exact />

                            <Route path="/admin/store/pages" element={<Pages />} exact />
                            <Route path="/admin/store/pages/add" element={<AddPages />} exact />
                            <Route path="/admin/store/pages/:id" element={<AddPages />} exact />
                            <Route path="/admin/store/preferences" element={<Preferences />} exact />

                            <Route path="/admin/store/menus" element={<Menu />} exact />
                            <Route path="/admin/store/menus/add" element={<AddMenuNav />} exact />
                            <Route path="/admin/store/menus/:id" element={<EditMenu />} exact />

                            <Route path="/admin/store/redirects" element={<URlRedirects />} exact />
                            <Route path="/admin/store/redirects/add" element={<AddURlRedirects />} exact />
                            <Route path="/admin/store/redirects/:id" element={<AddURlRedirects />} exact />
                            <Route path="/admin/settings/checkout-setting" element={<CheckoutSetting />} exact />
                            <Route path="/admin/settings/marketing" element={<Marketing />} exact />
                            <Route path="/admin/apps/delivery-partners" element={<Partners />} exact />
                            <Route path="/admin/settings/marketing" element={<Marketing />} exact />
                          </Route>
                        </Routes>
                      </Box>
                      <FooterMain />
                    </Box>
                  </Router>
              }
            </>
          }
        </Box>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
