import { alpha, Box, Button, Card, CardContent, Chip, Container, Divider, FormControl, FormControlLabel, Grid, InputBase, LinearProgress, Radio, Select, styled, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import config from '../../../../Container/config'
import * as Api from "../../../../Container/config/Apis"
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import _ from "underscore"
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import loader from "../../../../Assets/images/loader.svg";
import dayjs from 'dayjs';
import PopupModal from '../../../common/PopupModal/PopupModal';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import DialogAlert from '../../../common/Alerts/Dialog';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.0),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.0),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(3),
        // width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    display: 'flex',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        // borderRadius:"10px",
        // background: "#f7f8fa",
        margin: 0,
        // display: 'flex',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    '&.Mui-error': {
        border: "1px solid #d32f2f",
        backgroundColor: "rgba(255, 0, 0, 0.05)"
    },
}));
export default function EditGiftCard() {
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const navigate = useNavigate()
    const param = useParams()
    const [loadingBar, setLoadingBar] = useState(true)
    const [editNote, setEditNote] = useState(false)
    const [cardData, setCardData] = useState({})
    const [editData, setEditData] = useState({})
    const [is_expire, setISexpire] = useState("no")
    const [expire_at, setExpireAt] = useState(new Date())
    const [openModal, setOpenModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("Edit expiration date");
    const [loading, setLoading] = useState(false)
    const [sendCard, setSendCard] = useState(false)
    const [hideSearch, setHideSearch] = useState(true)
    const [params, setParams] = useState({
        search: "",
        page: 1
    })
    const [userList, setuserList] = useState([]);
    const [error, setError] = React.useState(false);
    const [hasNoMoreData2, sethasNoMoreData2] = useState(false)
    const [page, setPage] = useState(1)
    const [searchUser, setSearchUser] = useState('')
    const [showLoader, setshowLoader] = useState(false)
    const [loadingBar2, setloadingBar2] = React.useState(true);
    const [showDialog, setOpen] = React.useState(false);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: "",
        step: 1
    });
    const [confirmText, setconfirmText] = useState("Yes")

    const storeVal = useSelector((state) => state.dataValue)

    const closeDialog = () => {
        setOpen(false);
        setconfirmText("Yes")
        setContent({ ...content, message: "", title: "Are you sure?", step: 1 })
    }
    const handleClose = () => {
        setOpenModal(false);
    }

    const updateCardFn = (body) => {
        setLoading(true)
        Api.PutApi(`${config.GIFTCARD}/update/${param.id}`, body).then(res => {
            setLoading(false)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack(res.data.data, 'success');
                // navigate("/admin/gift-card")
            }
        })
    }

    const updateCard = () => {
        if (cardData.customer && cardData.customer.customer_id) {
            let body = {
                note: cardData.note,
                expire_at: is_expire == "yes" ?
                    cardData?.expire_at :
                    null,
                customer_id: cardData.customer.customer_id,
            }
            console.log("body", body)
            updateCardFn(body)
        } else {
            setError(true)
        }
    }

    const getCardDetail = (id) => {
        Api.GetApi(`${config.GIFTCARD}/${id}`).then(res => {
            console.log("res detail: ", res)
            setLoadingBar(false)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                setCardData(res.data.data)
                if (res.data.data.expire_at) {
                    setISexpire("yes")
                    setExpireAt(new Date(res.data.data.expire_at))
                }
            }
        })
    }

    const handleOnChange = (e) => {
        setCardData({
            ...cardData,
            [e.target.name]: e.target.value
        })
    }

    const handleExpire = (e) => {
        setISexpire(e.target.value)
    }

    const handleDateChange = (newValue) => {
        setExpireAt(newValue);
    };

    const onAccept = (newValue) => {
        if (newValue && newValue.isBefore(dayjs())) {
            setExpireAt(dayjs(cardData.expire_at));
        }
    }
    const savebtnFunct = () => {
        setCardData({
            ...cardData,
            expire_at: is_expire == "yes" ?
                _.isObject(expire_at) ?
                    moment((expire_at.$d ? expire_at.$d : expire_at._d ? expire_at._d : expire_at)).format() :
                    expire_at :
                null
        })
        setOpenModal(false)
    }

    const removeUser = () => {
        setCardData({ ...cardData, 'customer': {} })
    }

    //Get user list
    const getUserList = (param) => {
        Api.GetApi(`${config.USER_LIST}${param}`).then(res => {
            console.log("res list: ", res)
            setloadingBar2(false)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let arr = []
                    res.data.data.map((opt) => {
                        arr.push({ ...opt, active: false })
                    })
                    let customerData = []
                    if (userList.length > 0) {
                        customerData = [...userList, ...arr]
                    } else {
                        customerData = arr
                    }
                    if (customerData.length) {
                        setuserList(customerData)
                        if (res.data.data.length < 20) {
                            sethasNoMoreData2(true)
                        } else {
                            sethasNoMoreData2(false)
                        }
                    }
                }
            }
        })
    }
    const disableFn = (id) => {
        Api.GetApi(`${config.GIFTCARD_DISABLED}/${id}`).then(res => {
            console.log("res disbaled: ", res)
            setloadingBar2(false)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                getCardDetail(param.id)
                closeDialog()
            }
        })
    }

    // const handleUserSearch = useCallback((e) => {
    //     const value = e.target.value.replace('&', '%26');
    //     setParams({ ...params, 'search': value })
    //     setSearchUser(value)
    //     if (value !== "") {
    //         getUserList(`?page=${params.page}&search=${value}`)
    //         setHideSearch(false)
    //     } else {
    //         getUserList(`?page=${params.page}`)
    //         setHideSearch(true)
    //     }
    // }, [params])

    const handleUserSearch = useCallback((e) => {
        const value = e.target.value.replace('&', '%26');
        setuserList([])
        setPage(1)
        setloadingBar2(true)
        setSearchUser(value)
        if (value) {
            setHideSearch(false)
        } else {
            setHideSearch(true)
            // setParams({ ...params, 'search': value })
        }
    }, [])


    const selectUser = (data) => {
        setCardData({ ...cardData, 'customer': data })
        setHideSearch(true)
        setParams({ ...params, 'search': "" })
        setSearchUser("")
    }

    const callFn2 = useCallback((type) => {
        let pageData = page
        if (type == "fetch") {
            pageData = page + 1
        }
        if (searchUser) {
            getUserList(`?search=${searchUser}&page=${pageData}`)
        } else {
            getUserList(`?page=${pageData}`)
        }
        setPage(pageData)
    }, [searchUser, userList])


    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchUser !== "") {
                setshowLoader(true)
            }
            callFn2("")
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchUser]);

    // useEffect(() => {
    // }, [searchUser])
    const fetchMoreUserData = () => {
        setshowLoader(true)
        setTimeout(() => {
            callFn2("fetch")
        }, 1000);
    }

    const openDisbleModal = () => {
        setOpen(true);
        setContent({ ...content, message: "Are you sure you want to disable this gift card.", step: 1 })
    }
    const confirmDialog = () => {
        setOpen(false);
        setTimeout(() => {
            if (content.step == 1) {
                setOpen(true);
                setconfirmText("Confirm")
                setContent({ ...content, message: "You can not enable this gift card again.", title: "This cannot be undone", step: 2 })
            } else {
                disableFn(param.id)
            }
        }, 300);
    }

    const sendGiftCard = () => {
        Api.GetApi(`${config.GIFTCARD}/sendmail/${cardData?.gift_card_id}`).then(res => {
            console.log("res sendmail: ", res)
            setLoadingBar(false)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack("Mail sent successfully", 'success');
                setSendCard(false)
            }
        })
    }

    useEffect(() => {
        getCardDetail(param.id)
    }, [])
    console.log("cardData", cardData)
    console.log("editdata", editData)
    return (
        <React.Fragment >
            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                <Container maxWidth="lg">
                    <Box sx={{ mb: 2 }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={8}>
                                <Button component={Link} variant="text" color="secondary" to="/admin/gift-card" startIcon={<ArrowBackIosIcon />}>
                                    {cardData && cardData.code}
                                </Button>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: "right" }}>
                                {cardData?.status == "disabled" ?
                                    <Button variant="contained" color="error" disabled>
                                        Disabled
                                    </Button>
                                    :
                                    <Button variant="contained" color="error" onClick={openDisbleModal}>
                                        Disable gift card
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={12} md={7}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant='h6'>
                                                {cardData?.code}
                                                <Chip label={cardData?.status} sx={{ textTransform: "capitalize", ml: 3 }} variant={"contained"} color={cardData?.status !== "active" ? "error" : "success"} size='small' />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4} sx={{ textAlign: "right", display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }}>
                                            <Box>
                                                <Typography variant='p' component={"strong"}>Expiration date</Typography>
                                                <br />
                                                <Typography variant='p'>{cardData?.expire_at ? moment(cardData?.expire_at).format("lll") : "Doesn't expire"}</Typography>
                                            </Box>
                                            {cardData?.status !== "disabled" ?
                                                <Button
                                                    variant='text'
                                                    sx={{ minWidth: "auto" }}
                                                    onClick={() => setOpenModal(true)}
                                                >
                                                    <EditIcon sx={{ fontSize: "18px !important" }} />
                                                </Button>
                                                : null
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} columns={12}>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Typography variant='p' component={"div"}>Balance</Typography>
                                                <Typography variant='h5' sx={{ m: 0 }}>
                                                    <CurrencyFormat value={parseFloat(cardData?.balance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Typography variant='p' component={"div"}>Initial balance</Typography>
                                                <Typography variant='h5' sx={{ m: 0 }}>
                                                    <CurrencyFormat value={parseFloat(cardData?.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Card sx={{ mb: 2, overflow: "inherit" }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-start" }}>
                                        <Typography variant="h6" component="div" gutterBottom> User  </Typography>

                                        {!_.isEmpty(cardData?.customer) && cardData?.status !== "disabled" ?
                                            <Box sx={{ textAlign: "end" }}>
                                                <Button variant="text" color="primary" onClick={() => setSendCard(!sendCard)}>
                                                    Send gift card
                                                </Button>
                                            </Box>
                                            : null
                                        }
                                    </Box>
                                    {cardData?.status !== "disabled" ?
                                        <Box sx={{ position: 'relative', mb: 2 }}>
                                            <Search>
                                                <SearchIconWrapper>
                                                    <SearchIcon color="grey" sx={{ fontSize: "18px !important" }} />
                                                </SearchIconWrapper>
                                                <StyledInputBase
                                                    placeholder="Search user"
                                                    inputProps={{
                                                        'aria-label': 'search',
                                                        value: searchUser
                                                    }}
                                                    autoComplete="off"
                                                    name="searchCustomer"
                                                    onChange={handleUserSearch}
                                                    error={error && (!cardData?.customer || !cardData?.customer.customer_id) ? true : false}
                                                    onKeyDown={(e) => e.stopPropagation()}
                                                />
                                            </Search>
                                            {error && (!cardData?.customer || !cardData?.customer.customer_id) ?
                                                <Typography variant='caption' color={"error"} sx={{ ml: 2 }}>Please add user</Typography>
                                                : null
                                            }
                                            {!hideSearch &&
                                                <Box className='user_search_box'>
                                                    {loadingBar2 ?
                                                        <LinearProgress className="top_loader" color="info" sx={{ mt: "0", mb: "21px" }} />
                                                        :
                                                        <>
                                                            {userList.length > 0 ?
                                                                <Box id="scrollableDiv" sx={{ maxHeight: 300, overflow: "auto" }}>
                                                                    <InfiniteScroll
                                                                        dataLength={userList.length}
                                                                        next={fetchMoreUserData}
                                                                        hasMore={hasNoMoreData2 ? false : true}
                                                                        loader={
                                                                            showLoader &&
                                                                            <Box className="pagination_loader">
                                                                                <img src={loader} alt="" width="50" />
                                                                            </Box>
                                                                        }
                                                                        endMessage={""}
                                                                        scrollableTarget="scrollableDiv"
                                                                    >
                                                                        {userList.map((user, index) => {
                                                                            return (
                                                                                <Button key={index} color="secondary" sx={{ textAlign: "left", justifyContent: 'flex-start' }} className="w-100" onClick={() => selectUser(user)}>
                                                                                    <Typography variant="body1" component="div">
                                                                                        <strong>{user?.name || `${user?.first_name} ${user?.last_name ? user?.last_name : ""}` || "Guest user"}</strong> <br />
                                                                                        <small style={{ color: "#999" }}>({user.email})</small>
                                                                                    </Typography>
                                                                                </Button>
                                                                            )
                                                                        })}
                                                                    </InfiniteScroll>
                                                                </Box>
                                                                :
                                                                <Typography variant="h6" component="h6" sx={{ p: 3, textAlign: "center" }} gutterBottom>No user found!</Typography>
                                                            }
                                                        </>
                                                    }
                                                </Box>
                                            }
                                        </Box>
                                        : null

                                    }

                                    {!_.isEmpty(cardData?.customer) &&
                                        <Grid container spacing={2} columns={12}>
                                            {!sendCard ?
                                                <>
                                                    <Grid item md={9}>
                                                        <Box>
                                                            <Button variant="text" component={Link} to={`/admin/users/${cardData?.customer?.customer_id || ""}`} className="noHover" sx={{ p: 0, justifyContent: "flex-start", fontSize: 14 }}>
                                                                {cardData?.customer?.name || `${cardData?.customer?.first_name} ${cardData?.customer?.last_name ? cardData?.customer?.last_name : ""}` || "Guest user"}
                                                            </Button>
                                                            <Typography variant="p" component="div" gutterBottom>
                                                                {cardData?.customer?.email || ""}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item md={3} sx={{ textAlign: "right" }}>
                                                        {cardData?.status !== "disabled" ?
                                                            <Button variant='text' color='error' sx={{ minWidth: "auto" }} onClick={removeUser}>
                                                                <CloseIcon sx={{ fontSize: "18px !important" }} />
                                                            </Button>
                                                            : null
                                                        }
                                                    </Grid>
                                                </>
                                                :
                                                <Grid item md={12}>
                                                    <Box>
                                                        <Typography variant="caption" component="div" gutterBottom color={"#999"}>
                                                            From
                                                        </Typography>
                                                        <Typography variant="p" component="div" gutterBottom sx={{ mb: 2 }}>
                                                            {storeVal && storeVal.store && storeVal.store.sender_email || ""}
                                                        </Typography>
                                                        <Typography variant="caption" component="div" gutterBottom color={"#999"}>
                                                            To
                                                        </Typography>
                                                        <Typography variant="p" component="div" gutterBottom sx={{ mb: 2 }}>
                                                            {cardData?.customer?.email || ""}
                                                        </Typography>
                                                        <Box>
                                                            <Button variant='text' color='error' sx={{ mr: 2 }} onClick={() => setSendCard(false)}>Cancel</Button>
                                                            <Button variant='contained' onClick={sendGiftCard}>Send email</Button>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                </CardContent>
                            </Card>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom sx={{ display: "flex", justifyContent: "space-between" }}> Notes
                                        <Button
                                            variant='text'
                                            sx={{ minWidth: "auto" }}
                                            onClick={() => setEditNote(!editNote)}
                                        >
                                            {editNote ?
                                                <CloseIcon sx={{ fontSize: "18px !important" }} />
                                                :
                                                <EditIcon sx={{ fontSize: "18px !important" }} />
                                            }
                                        </Button>
                                    </Typography>
                                    {!editNote ?
                                        <Typography variant="p" component="div" gutterBottom>{cardData?.note || "-"}</Typography>
                                        :
                                        <FormControl fullWidth >
                                            <TextField
                                                size="small"
                                                value={cardData?.note}
                                                name="note"
                                                onChange={handleOnChange}
                                            />
                                        </FormControl>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Box>
                        <Divider sx={{ my: "15px" }}></Divider>
                        <Box sx={{ textAlign: "left" }}>
                            <Button variant="text" color="error" tabIndex={-1} onClick={() => navigate("/admin/gift-card")}>Cancel</Button>
                            <LoadingButton
                                sx={{ ml: 1, whiteSpace: 'nowrap' }}
                                color="primary"
                                // disabled={body?.options.length > 0 && editProduct && prodOption.filter((x) => x?.edit == false).length > 0 ? true : false}
                                onClick={updateCard}
                                loading={loading}
                                loadingIndicator="Please wait..."
                                variant="contained"
                            >
                                Update
                            </LoadingButton>
                        </Box>
                    </Box>
                </Container>
            }

            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                confirmDialog={confirmDialog}
                confirmText={confirmText}
            />
            <PopupModal
                open={openModal}
                ModalClose={handleClose}
                title={modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                savebtnTxt={"Done"}
                savebtnFunct={savebtnFunct} >
                <Box>
                    <FormControlLabel control={<Radio color="secondary" onChange={handleExpire} id="no" value="no" checked={is_expire == "no"} />} label={"No expiration date"} />
                </Box>
                <Box>
                    <FormControlLabel control={<Radio color="secondary" onChange={handleExpire} id="yes" value="yes" checked={is_expire == "yes"} />} label={"Set expiration date"} />
                </Box>
                {is_expire == "yes" ?
                    <Box sx={{ mt: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <MobileDateTimePicker
                                value={expire_at}
                                onChange={(e) => handleDateChange(e)}
                                label="Select date"
                                onError={console.log}
                                minDate={dayjs(new Date())}
                                minTime={expire_at < new Date() && dayjs(new Date()).subtract(1, "minute")}
                                inputFormat="DD/MM/YYYY HH:mm A"
                                mask="____/__/__ __:__ _M"
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                onAccept={onAccept}
                            />
                        </LocalizationProvider>
                    </Box>
                    : null
                }
            </PopupModal>
        </React.Fragment>
    )
}
