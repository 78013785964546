import React, { useState, useEffect } from 'react'
import { Box, Card, CardContent, Container, IconButton, LinearProgress, Paper, } from '@mui/material';
import _ from 'underscore';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ClearIcon from '@mui/icons-material/Clear';
import { Draggable } from "react-drag-reorder";
import ReactPlayer from "react-player"
import { ImageCard } from '../../Pages/Product/ImageCard';
import { getFileType, getId } from '../../../Container/config/Apis';
import { Delete } from '@material-ui/icons';

export default function Fileupload({ onSelectFile, preview, deleteImage, moveCard, editProduct }) {
    // const thumbs = preview.length > 0 && preview.map((file, i) => {
    //     return (
    //         <Paper className="filePreviewBox" sx={{ mb: 0.2 }} key={i.toString()} elevation={3}>
    //             <div className="filePreviewBox__img" >
    //                 <img src={file} />
    //             </div>
    //             <div className="filePreviewBox__cntnt">
    //                 {/* <Box sx={{p:2, pt: 1}} className="img_uploading">
    //                     <LinearProgress color="info" />
    //                 </Box> */}
    //                 <IconButton className='img_delete' aria-label="delete" fontSize="12px" color="error" onClick={() => deleteImage(i)}><ClearIcon /></IconButton>
    //             </div>
    //         </Paper>
    //     )
    // });

    // const videoId = getId('http://www.youtube.com/watch?v=zbYf5_S7oJo');


    useEffect(() => {

    }, [preview]);
    return (
        <>
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Box className="container">
                        <Box className={`fileUploader ${preview.length > 0 ? "active" : null}`}>
                            {/* <Draggable onPosChange={getChangedPos}> */}
                            {preview.length > 0 && preview.map((file, i) => {
                                let url = getId(file.src ? file.src : file)
                                // console.log("url: ", url)
                                return (
                                    <React.Fragment key={i.toString()}>
                                        {!editProduct ?
                                            <Paper className="filePreviewBox" sx={{ mb: 0.2 }} elevation={3}>
                                                <div className="filePreviewBox__img" >
                                                    {!url ?
                                                        <>
                                                            {file && file.startsWith("data:video") ?
                                                                <video src={file} controls width="100%" height="100%" /> :
                                                                <img src={file} />
                                                            }
                                                        </>
                                                        :
                                                        <ReactPlayer
                                                            // url="https://vimeo.com/3155182"
                                                            url={file}
                                                            controls={true}
                                                            width={"100%"}
                                                            height={"100%"}
                                                            playsinline={true}
                                                        />
                                                    }
                                                </div>
                                                <div className="filePreviewBox__cntnt">
                                                    <IconButton className='img_delete' aria-label="delete" fontSize="12px" color="error" onClick={() => deleteImage(file, i)}><Delete /></IconButton>
                                                </div>
                                            </Paper>
                                            :
                                            <ImageCard
                                                index={i}
                                                id={file.id}
                                                text={<Paper className="filePreviewBox" sx={{ mb: 0.2 }} elevation={3}>
                                                    <div className="filePreviewBox__img" >
                                                        {!url ?
                                                            <>
                                                                {getFileType(file.src)}
                                                            </>
                                                            :
                                                            <ReactPlayer
                                                                // url="https://vimeo.com/3155182"
                                                                url={file.src ? file.src : file}
                                                                controls={true}
                                                                width={"100%"}
                                                                height={"100%"}
                                                                playsinline={true}
                                                            />
                                                        }
                                                    </div>
                                                    <div className="filePreviewBox__cntnt">
                                                        <IconButton className='img_delete' aria-label="delete" fontSize="12px" color="error" onClick={() => deleteImage(file.src ? file.src : file, i)}><Delete /></IconButton>
                                                    </div>
                                                </Paper>}
                                                moveCard={moveCard}
                                            />
                                        }
                                    </React.Fragment>
                                )
                            })}
                            {/* </Draggable> */}
                            <Box className="customFileUpload">
                                <input
                                    multiple
                                    accept=".jpg,.jpeg,.png,.webp,.svg,.mp4,.mkv,.avi,.webm"
                                    type='file'
                                    onChange={onSelectFile}
                                />
                                <label>
                                    <FileUploadIcon fontSize='small' sx={{ verticalAlign: "middle" }} /> Choose files

                                </label>
                            </Box>
                        </Box>
                    </Box>

                </CardContent>
            </Card>
        </>
    )
}